import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/session";
import ReactTooltip from "react-tooltip";

import Kathleen from "../../assets/kathleen/Kathleen_500_500.png";
import git from "../../assets/about/git-logo.png";
import linkedin from "../../assets/about/linkedin-logo.png";
import skyline from "../../assets/about/skyline.png";

import { Modal } from "../../context/Modal";
import Login from "../NavBar/Login";

const Membership = () => {
	const dispatch = useDispatch();
        const user = useSelector((state) => state.session.user);
        const [showLogin, setShowLogin] = useState(false);
        const [showMenu, setShowMenu] = useState(false);

        const dropdownRef = useRef(null);

        const openMenu = (e) => {
                e.preventDefault();
                setTimeout(() => {
                        setShowMenu(true);
                }, 1);
                document.addEventListener("click", closeMenu);
        };

        const closeMenu = (e) => {
                e.preventDefault();
                if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                        setShowMenu(false);
                        document.removeEventListener("click", closeMenu);
                }
        };

        const onLogin = async (e) => {
                e.preventDefault();
                const email = "demo@aa.io";
                const password = "password";
                await dispatch(login(email, password));
        };

        const onAgentLogin = async (e) => {
                e.preventDefault();
                const email = "agent1@user.com";
                const password = "password";
                await dispatch(login(email, password));
        };

        const onClose = () => {
                setShowLogin(false);
        };

	return (
		<div
			className="about-ctrl"
			style={{ backgroundImage: `url("${skyline}")` }}
		>
			<ReactTooltip />
			<div className="wrapper">
				<div>
					<div className="name">Join 1%Percentors today!</div>
					<div className="slogan">Become a member of the 1% realtors.  As a 1% percent agent you and your client agree to a 1% commission paid at closing.  With the changes in the real estate market we are rethinking how real estate commissions are earned.  Join the growing group of real estate agents redefining the real estate industry.</div>
				</div>
				<div className="icon-group">
                                        <button className="btn-font-lt" onClick={() => setShowLogin(true)}>
                                                Join Now 
                                        </button>
                                        {showLogin && (
                                                <Modal onClose={onClose}>
                                                        <Login onClose={onClose}></Login>
                                                </Modal>
                                        )}
				</div>
			</div>
		</div>
	);
};

export default Membership;
